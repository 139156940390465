<template>
  <div class="not-found">
    <h3>Page Not Found.</h3>
    <router-link :to="homeDestination" class="block q-mt-sm"
      >Take me home.</router-link
    >
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    homeDestination: {
      type: String,
      required: false,
      default: "/"
    }
  }
};
</script>

<style scoped lang="scss">
.not-found {
  // ...
}
</style>
